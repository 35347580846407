.green-row,
.green-row:hover {
  background-color: green !important;
}
.yellow-row,
.yellow-row:hover {
  background-color: lightyellow !important;
}
.gray-row,
.gray-row:hover {
  background-color: lightcyan !important;
}
.blue-row,
.blue-row:hover {
  background-color: #f4f6ff !important;
}
.ku-font {
  font-family: 'Noto Kufi Arabic', sans-serif !important;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(../fonts/English/Montserrat-Light.otf) format('truetype');
}
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC&display=swap');

@font-face {
  font-family: 'Rabar_013';
  src: local('Rabar_013'),
    url(../fonts/Kurdish_Arabic/Rabar_013.ttf) format('truetype');
}
@font-face {
  font-family: 'Rabar_014';
  src: local('Rabar_014'),
    url(../fonts/Kurdish_Arabic/Rabar_014.ttf) format('truetype');
}
@font-face {
  font-family: 'UniQAIDAR_Blawkrawe';
  src: local('UniQAIDAR_Blawkrawe'),
    url(../fonts/Kurdish_Arabic/UniQAIDAR_Blawkrawe.ttf) format('truetype');
}
.main-wrapper[lang='en'] {
  font-family: 'Noto Sans SC', sans-serif;
}
.main-wrapper[lang='ku'],
.main-wrapper[lang='ar'] {
  font-family: 'Rabar_014';
}

/* Dashboard styles */
.iconWrapper {
  top: -58px;
  position: relative;
  margin-bottom: -40px;
}
.bigIconContainer {
  width: 70px;
  height: 70px;
  margin: auto;
  color: #fff;
  position: relative;
  border-radius: 100px;
  box-shadow: rgb(221 228 231) 0px -5px 9px;
}

.bigIconContainer > .anticon {
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.customGutter {
  margin: 50px -7.5px 5px;
}

.customGutter > .ant-col {
  padding: 5px 7.5px;
}

@media screen and (max-width: 992px) {
  .customGutter {
    margin: 50px -7.5px 27.5px;
  }

  .customGutter > .ant-col {
    padding: 27.5px 7.5px;
  }
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: rgb(37, 34, 34);
}

.ant-tabs-tab {
  width: 20vh;
  display: flex;
  justify-content: center;
  font-weight: 500;
}

.transactionItemContainer {
  display: grid;
  text-align: center;
  border-bottom: thin solid #f0f0f0;
}

.transactionViewContainer {
  display: grid;
  text-align: center;
  border-bottom: thin solid #f0f0f0;
  grid-template-columns: 50px 20% 20% 150px 150px 1fr;
}

.transactionItemContainer > div,
.transactionViewContainer > div {
  padding: 15px 0;
  align-self: center;
}

.transactionContainer {
  margin-bottom: 20px;
}

.transactionInput {
  position: relative;
  padding: 4px 6px;
  border-radius: 3px;
  border: thin solid #d9d9d9;
}

.transactionInputHeader {
  padding: 0px 0 6px 6px;
}

.red-row,
.red-row:hover {
  background-color: #fff4f4 !important;
}

.unseen-row {
  position: relative;
  box-shadow: 0px 0px 12px -1px #76767652;
  -webkit-box-shadow: 0px 0px 12px -1px #76767652;
  -moz-box-shadow: 0px 0px 12px -1px #76767652;
}

.unseen-row::after {
  content: 'New';
  display: block;
  position: absolute;
  left: -11px;
  top: 50%;
  transform: translateY(-50%);
  padding: 2px 5px;
  background: #52c41a;
  color: #fff;
  border-radius: 14px;
  font-size: 12px;
}

.ant-checkbox-wrapper {
  font-size: 20px !important;
  padding: 11px !important;
}

.green-row,
.green-row:hover {
  background-color: #f4fff5 !important;
}

.dashboardCards {
  margin-top: 25px;
  height: 30vw;
  border: 3px solid #3d3d3d;
  border-radius: 0px 10px;
  text-align: center;
  width: 24%;
  margin-left: 10px;
}
.dashboardHeaders {
  font-size: 1.8rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5vw;
  border-bottom: 2px dashed black;
}
.dashboardMenu {
  font-size: 1.3rem;
  background-color: white;
  transition: all;
  transition-duration: 0.5s;
}
.dashboardMenu:hover {
  color: white;
  font-size: 1rem;
  background-color: #05b0b7;
}

.bt1:hover {
  border: 1px solid #10a5f5;
}
.bt2:hover {
  border: 1px solid red;
}
.bt3:hover {
  border: 1px solid #d4af37;
}
.btn_icon {
  color: #d4af37;
}

.bt4_hover:hover {
  border: 1px solid #259a7a;
}

.contract_action .ant-btn span {
  margin-right: 0;
}
/* //////////////////////////////////////// */

.avatar_menu_badge > sup {
  right: -10px;
}
